<template>
    <div v-if="!error" class="block">
        <div class="row mt-3">
            <div class="col-4 name_input">
                <input v-model="filters.q" class="form-control name_input" placeholder="Vyhledat podle názvu nebo IČA">
            </div>
            <div class="col-4">
                <VueMultiselect v-model="filters.employees" :options="employees" :searchable="true" :multiple="true"
                    label="name" placeholder="Počet zaměstnanců" class="w-100 multiselect" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :deselect-label="$t('multiselect.pressToDeselect')"
                    :selected-label="$t('multiselect.selected')" :limit="3" :limit-text="getLimitText"
                    :showNoResults="false">>
                </VueMultiselect>
            </div>
            <div class="col-4">
                <VueMultiselect v-model="filters.trades" :options="trades" :searchable="true" :multiple="true"
                    group-label="name" group-values="data" label="name" placeholder="Živnosti" class="w-100 multiselect"
                    track-by="id" :select-label="$t('multiselect.pressToSelect')"
                    :deselect-label="$t('multiselect.pressToDeselect')" :selected-label="$t('multiselect.selected')"
                    :limit="3" :limit-text="getLimitText" :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.legalForms" :options="legalForms" :searchable="true" :multiple="true"
                    label="name" placeholder="Typ společnosti" class="w-100 multiselect" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :deselect-label="$t('multiselect.pressToDeselect')"
                    :selected-label="$t('multiselect.selected')" :limit="3" :limit-text="getLimitText"
                    :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.tags" :options="tags" :searchable="true" :multiple="true" label="name"
                    placeholder="Štítky" class="w-100 multiselect" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :deselect-label="$t('multiselect.pressToDeselect')"
                    :selected-label="$t('multiselect.selected')" :limit="3" :limit-text="getLimitText"
                    :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.creation_date" :options="dateOptions" placeholder="Datum založení"
                    class="w-100 multiselect" label="label" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :selected-label="$t('multiselect.selected')"
                    :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.expired" :options="expired" placeholder="Aktivní i zaniklé"
                    class="w-100 multiselect" label="label" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :selected-label="$t('multiselect.selected')"
                    :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.user_in_contact" :limit="2" :options="users" :multiple="true"
                    placeholder="V kontaktu s" class="w-100 multiselect" label="name" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :selected-label="$t('multiselect.selected')"
                    :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false"
                    :limit-text="getLimitText">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2 align-self-center">
                <!-- Button trigger modal -->
                <div class="address_toggle d-flex align-items-center" data-bs-toggle="modal"
                    data-bs-target="#addressModal">
                    <span>{{ $t('company.searchByAddress') }}</span>
                </div>
                <!-- Modal -->
                <div class="modal fade" id="addressModal" tabindex="-1" aria-labelledby="addressModal"
                    aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="addressModal">{{ $t('company.searchByAddress') }}</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div>
                                        <input type="checkbox" v-model="manualFilters.address.residence"
                                            class="form-check-input me-2" id="residence">
                                        <label for="residence" class="form-label">{{ $t('company.residence') }}</label>
                                    </div>
                                    <div>
                                        <input type="checkbox" v-model="manualFilters.address.branch"
                                            class="form-check-input me-2" id="branch">
                                        <label for="branch" class="form-label">{{ $t('company.branch') }}</label>
                                    </div>
                                    <div class="mt-3">
                                        <label for="branch" class="form-label">{{ $t('company.searchByAddress')
                                            }}</label>
                                        <input type="text" :placeholder="$t('general.writeAddress')"
                                            v-model="manualFilters.address.address" class="form-control me-2"
                                            id="office">
                                    </div>
                                    <div class="mt-3">
                                        <label for="branch" class="form-label">{{ $t('company.searchByRegion')
                                            }}</label>
                                        <VueMultiselect v-model="manualFilters.address.regions" :options="regions"
                                            placeholder="Kraje" class="w-100 address_multiselect" label="name"
                                            track-by="id" :select-label="$t('multiselect.pressToSelect')"
                                            :selected-label="$t('multiselect.selected')"
                                            :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false"
                                            :multiple="true" @change="updateDistricts">
                                        </VueMultiselect>
                                    </div>
                                    <div class="mt-3" v-if="manualFilters.address.regions.length > 0">
                                        <label for="branch" class="form-label">{{ $t('company.searchByDistrict')
                                            }}</label>
                                        <VueMultiselect v-model="manualFilters.address.districts"
                                            :options="availableDistricts" placeholder="Okresy" class="w-100"
                                            label="name" track-by="id" :select-label="$t('multiselect.pressToSelect')"
                                            :selected-label="$t('multiselect.selected')" :multiple="true"
                                            :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false">
                                        </VueMultiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn_secondary" data-bs-dismiss="modal">
                                    {{ $t('utils.close') }}
                                </button>
                                <button type="button" class="btn btn_main" @click="applyFilters">
                                    {{ $t('utils.submit') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loading" class="loading-container mt-3">
            <div class="d-flex align-items-center justify-content-center flex-column">
                <SpinningWheel />
            </div>
        </div>
        <div v-else-if="!loading" style="max-height: 460px; overflow-y: auto;" class="table_container mt-3">
            <table class="table table-hover">
                <thead class="table_head">
                    <tr>
                        <th class="col-2">{{ $t("company.companyName") }}</th>
                        <th class="col-1">{{ $t("company.organizationId") }}</th>
                        <th class="col-3">{{ $t("company.address") }}</th>
                        <th class="col-2">{{ $t("company.inContactWith") }}</th>
                        <th class="col-2">{{ $t("company.tags") }}</th>
                        <th class="col-2"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="company in companies" :key="company.slug" class="align-middle">
                        <td class="col-2">
                            <span v-if="company.name">{{ company.name }}</span>
                            <span v-else>---</span>
                        </td>
                        <td class="col-1">
                            <span v-if="company.organization_id">{{ company.organization_id }}</span>
                            <span v-else>---</span>
                        </td>
                        <td class="col-3">
                            <span v-if="company.address">{{ company.address }}</span>
                            <span v-else>---</span>
                        </td>
                        <td class="col-2 p-0">
                            <div class="d-flex align-items-center user_avatar">
                                <template v-if="company.in_contact_with && company.in_contact_with.length > 0"
                                    class="user_avatar">
                                    <UserAvatar v-for="user in company.in_contact_with" :key="user.id"
                                        :user="user.institution_user.user" :user_role="user.institution_user.role"
                                        class="me-2 user_avatar" />
                                </template>
                                <span v-else>---</span>
                            </div>
                        </td>
                        <td class="col-2">
                            <div class="d-flex w-100">
                                <span v-if="company.tags.length === 0" class="text-muted">{{ $t('company.noTag')
                                    }}</span>

                                <template v-else>
                                    <span v-for="(tag, index) in company.tags" :key="index">
                                        <Tag :color="tag.color" :bg="tag.bg_color" :text="tag.name" class="me-1" />
                                    </span>
                                </template>
                            </div>
                        </td>
                        <td class="col-2 text-end pe-4">
                            <router-link :to="{ name: 'company', params: { slug: company.slug } }"
                                class="btn btn-primary button_detail p-0">
                                <icon icon="Info"></icon>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="loading-container" style="max-height: 200px; min-height: 200px;" v-if="companies.length == 0">
                <span>{{ $t("general.companiesNotFound") }}</span>
            </div>
        </div>
    </div>
    <div v-if="error">
        <CompaniesNotPaidError :error="error" />
    </div>
    <div v-if="!error && !loading" class="row justify-content-between mx-4 page_counter">
        <Paginator v-model:page="filters.page" :pages="Math.ceil(totalCount / filters.per_page)"
            class="col-auto mx-4" />
        <div class="col-auto mx-2 page_number d-flex">
            <select v-model="filters.per_page" class="form-select align-self-center h-100">
                <option v-for="page in perPage" :key="page" :value="page" class="">
                    <span class="">{{ page }}</span>
                </option>
            </select>
            <icon icon="ArrowUp" class="arrow_up_icon my-auto"></icon>
        </div>
    </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import Paginator from '@/components/Paginator.vue';
import SpinningWheel from '@/components/SpinningWheel.vue';
import UserAvatar from "@/components/CompanyDetail/UserAvatar.vue";
import Tag from '@/components/Tag.vue';
import { mapGetters, mapActions } from 'vuex';
import BaseTemplate from '@/components/templates/BaseTemplate.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import CompaniesNotPaidError from '@/components/CompaniesNotPaidError.vue';

export default {
    components: {
        VueMultiselect,
        Paginator,
        SpinningWheel,
        Tag,
        UserAvatar,
        BaseTemplate,
        LanguageSelector,
        CompaniesNotPaidError
    },
    data() {
        return {
            companies: [],
            totalCount: 0,
            employees: [],
            trades: [],
            legalForms: [],
            tags: [],
            perPage: [
                1, 5, 10, 20, 50, 100
            ],
            loading: false,
            debouce: 700,
            timeout: null,
            dateOptions: [
                { id: 'last7', label: this.$t('general.last7Days') },
                { id: 'last30', label: this.$t('general.last30Days') },
                { id: 'last90', label: this.$t('general.last90Days') },
                { id: 'last365', label: this.$t('general.last365Days') }
            ],
            expired: [
                { id: 'false', label: this.$t('general.active') },
                { id: 'true', label: this.$t('general.expired') }
            ],
            users: [],
            error: false,
            regions: [],
            availableDistricts: [],
            currentPage: null
        }
    },
    computed: {
        ...mapGetters(['filters']), // Map Vuex getters
        manualFilters: {
            get() {
                return this.$store.getters.manualFilters;
            },
            set(value) {
                this.$store.commit('setManualFilters', value);
            }
        },
        userName() {
            return this.users.map(user => {
                return {
                    ...user,
                    displayName: user.name || user.username
                }
            })
        },
        updateDistricts: function () {
            if (this.manualFilters.address.regions && this.manualFilters.address.regions.length > 0) {
                var activeRegions = this.manualFilters.address.regions;

                this.availableDistricts = activeRegions.flatMap(region => region.districts);

            } else {
                this.availableDistricts = [];
            }
        },
    },
    created: function () {
        this.loadData();
    },
    watch: {
        filters: {
            handler(newFilters) {
                this.updateFilters(newFilters);
                this.loadCompanies();
                console.log('Filters updated:', newFilters);
                if (newFilters.page != this.currentPage) {
                    console.log("ads")
                }
                this.currentPage = newFilters.page
                console.log('Current page:', this.currentPage);
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        ...mapActions(['updateFilters']), // Map Vuex actions
        loadData: function () {
            this.loadCompanies();

            this.$store.getters.api.get('/employees').then(function (response) {
                this.employees = response.data.items;
            }.bind(this))

            this.$store.getters.api.get('/trades').then(function (response) {
                this.trades = response.data.items;
            }.bind(this))

            this.$store.getters.api.get('/legal_forms').then(function (response) {
                this.legalForms = response.data.items;
            }.bind(this))

            this.$store.getters.api.get('/regions').then(function (response) {
                this.regions = response.data.regions;
            }.bind(this))

            this.$store.getters.api.get('/tags').then(function (response) {
                this.tags = [{ id: 'no_tag', name: this.$t('company.noTag') }, ...response.data.items];
            }.bind(this))
            this.$store.getters.api.get(`institution/${this.$store.getters.selectedInstitution}/users`).then(function (response) {
                this.users = [{ id: 'no_user', name: this.$t('company.noUser') }, ...response.data.items];
            }.bind(this))
        },
        loadCompanies: function () {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(function () {
                var params = {
                    ...this.filters
                };
                if (params.creation_date) {
                    params.creation_date = params.creation_date.id;
                }

                if (params.expired) {
                    params.expired = params.expired.id;
                }
                if (params.user_in_contact) {
                    if (params.user_in_contact.id === 'no_user') {
                        params.no_user = true;
                        params.user_in_contact = null;
                    }
                    else {
                        params.no_user = false;
                        var users = []
                        params.user_in_contact.forEach(element => {
                            users.push(element.id);
                        });
                        params["users_in_contact"] = users
                    }
                }

                if (params.employees) {
                    var employees = []
                    params.employees.forEach(element => {
                        employees.push(element.id);
                    });
                    params.employees = employees;
                }

                if (params.trades) {
                    var trades = []
                    params.trades.forEach(element => {
                        trades.push(element.id);
                    });
                    params.trades = trades;
                }

                if (params.legalForms) {
                    var legalForms = []
                    params.legalForms.forEach(element => {
                        legalForms.push(element.id);
                    });
                    params.legalForms = legalForms;
                }

                if (params.tags) {
                    var tags = [];
                    params.tags.forEach(element => {
                        if (element.id !== 'no_tag') {
                            tags.push(element.id);
                        }
                        else {
                            tags.push('no_tag');
                            params.no_tag = true;
                        }
                    });
                    params.tags = tags;
                }

                this.loading = true;
                this.error = false
                this.$store.getters.api.post('/companies', params).then(function (response) {
                    const data = response.data;
                    this.companies = response.data.results;
                    this.totalCount = response.data.count;
                    this.loading = false;

                    if (data.error_code == 401) {
                        this.error = (this.$t("error.401"));
                    }

                }.bind(this))
            }.bind(this), this.debouce);
        },
        applyFilters() {
            this.$store.commit('setManualFilters', this.manualFilters);

            const combinedFilters = { ...this.filters, address: this.manualFilters.address };
            this.updateFilters(combinedFilters);
            this.loadCompanies();
        },
        getLimitText(count) {
            return this.$t('multiselect.andMore', { count });
        },
    },
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.name_input {
    border-radius: 65px !important;
    color: $dark-grey;
    height: 50px;

    input:focus {
        padding-left: 25px;
    }
}

.name_input::placeholder {
    color: $black;
    opacity: 0.9;
    font-size: 18px;
    font-weight: 400;
    padding: 8px;
}

::v-deep .multiselect,
::v-deep .multiselect__tags {
    border-radius: 65px !important;
}

.modal {
    color: $black;

    #office {
        padding: 10px 10px 10px 20px;
        border-radius: 65px;
    }
}

::v-deep .address_multiselect {

    .multiselect__tags,
    .multiselect-caret,
    .multiselect__placeholder {
        cursor: pointer;
        max-height: 35px;
        font-size: 1rem;
        padding: 4px 10px 40px 10px;
    }
}

.address_toggle {
    background-color: $white;
    color: $dark-grey;
    border-radius: 65px;
    height: 50px;
    cursor: pointer;

    span {
        padding: 20px;
        font-size: 18px;
        font-weight: 400;
    }
}

.button_detail {
    border: none;
    background-color: transparent;

    svg {
        width: 42px;
        height: 42px;
    }
}

.button_detail:hover {
    background: transparent;
    border: none;

}

.button_detail:focus {
    background: none;
    border: none;
}

.loading-container {
    min-height: 500px;
    /* Minimum height to prevent layout shift */
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    span {
        color: black;
    }
}

.page_counter {
    margin-top: 1.5%;
    border-radius: 50px;
}

.page_number {
    background: linear-gradient(90deg, $purple 26%, $light-purple 100%);
    border: none;
    border-radius: 35px;
    height: 44px;
    width: 190px;

    .form-select {
        background: none;
        border: none;
        color: $white;
        font-weight: 400;
        font-size: 24px;
        font-family: 'Rubik One Regular', sans-serif;

        option {
            margin: 0;
            text-align: right;
            color: $black;
        }
    }

    .form-select:focus {
        outline: none;
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    svg {
        stroke: $white;
        text-align: left;
        margin-left: -30px;
    }
}
</style>