<template>
    <CompanyBoxContent :title="$t('company.yourInfo') + ':'">
        <template #data>
            <div class="company_tags">
                <!-- TOP -->
                <div class="box tags_box">
                    <div class="d-flex justify-content-between">
                        <div class="py-auto my-auto">
                            <span>{{ $t("company.tags") }}:</span>
                        </div>
                        <div class="d-flex align-self-center div_add_tag my-auto">
                            <button @click="toggleAddingNewTag"
                                class="btn btn_main btn_add_tag d-flex align-self-center my-auto">
                                <icon v-if="!addingNewTag" icon="Plus" class="icon_plus"></icon>
                                <icon v-if="addingNewTag" icon="Plus" class="icon_plus"
                                    style="transform: rotate(45deg);"></icon>
                            </button>
                        </div>
                    </div>
                    <!-- List of tags -->
                    <div class="d-flex mt-2">
                        <span v-if="!company.tags || company.tags.length == 0">
                            - {{ $t("company.noTag") }}
                        </span>
                        <template v-else>
                            <Tag v-for="(tag, index) in company.tags" :key="index" :color="tag.color" :bg="tag.bg_color"
                                :text="tag.name" :showDeleteButton="true" :tagId="tag.id" @delete="deleteTag(tag.id)"
                                class="w-100 me-2" />
                        </template>
                    </div>
                </div>
                <!-- Adding new tag -->
                <div v-if="addingNewTag" class="mt-2">
                    <VueMultiselect v-model="selectedTags" :options="tags" :searchable="true" :multiple="true"
                        label="name" placeholder="Štítky" class="w-100" track-by="id"
                        :select-label="$t('multiselect.pressToSelect')"
                        :deselect-label="$t('multiselect.pressToDeselect')">
                    </VueMultiselect>
                    <div class="d-flex justify-content-end mt-2">
                        <button @click="submitNewTag()" class="btn btn_main">{{ $t("utils.add") }}</button>
                    </div>
                </div>
                <div class="row mt-2 w-100 g-2 px-0">
                    <!-- Button for in contact with company -->
                    <div class="box py-2">
                        <div class="d-flex w-100 justify-content-between">
                            <div class="align-self-center">
                                <span v-if="!company.is_in_contact">{{ $t('company.imNotInContactWithCompany') }}</span>
                                <span v-if="company.is_in_contact">{{ $t('company.imInContactWithCompany') }}</span>
                            </div>
                            <div class="d-flex align-self-center">
                                <button v-if="!company.is_in_contact" @click="addInContact()"
                                    class="btn me-2 btn_contact_cancel  align-self-center justify-content-center">
                                    <icon icon="False"></icon>
                                </button>
                                <button v-if="company.is_in_contact" @click="deleteInContact()"
                                    class="btn btn_contact align-self-center justify-content-center">
                                    <icon icon="True"></icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- User avatar -->
                    <div class="box mt-3 g-2 in_contact_with">
                        <span>{{ $t('company.inOthersContactWith') }}:</span>
                        <span v-if="company.other_users_in_contact && company.other_users_in_contact.length > 0"
                            v-for="user in company.other_users_in_contact" :key="user.id" class="d-flex">
                            <UserAvatar v-if="user" :user="user.user" :user_role="user.role" class="px-1" />
                        </span>
                        <span v-else class="d-flex mt-1">
                            <span>- {{ $t('company.noOne') }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import Tag from '@/components/Tag.vue';
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"
import UserAvatar from "@/components/CompanyDetail/UserAvatar.vue";
import VueMultiselect from 'vue-multiselect';

export default {
    components: {
        Tag,
        CompanyBoxContent,
        VueMultiselect,
        UserAvatar
    },
    props: {
        company: {
            type: Object,
            required: true
        },
        tags: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            addingNewTag: false,
            selectedTags: []
        }
    },
    methods: {
        toggleAddingNewTag() {
            this.addingNewTag = !this.addingNewTag;
        },
        submitNewTag() {
            // Extract array of tag ids
            const tagIds = this.selectedTags.map(tag => tag.id);

            this.$store.getters.api.post(`/company/${this.$route.params.slug}/tag/add`, { tag_ids: tagIds })
                .then(response => {
                    this.$emit('reload');
                    this.addingNewTag = false
                });
        },
        deleteTag(tagId) {
            this.$store.getters.api.delete(`/company/${this.$route.params.slug}/tag/${tagId}/delete`)
                .then(response => {
                    this.$emit('reload');
                });
        },
        addInContact() {
            this.$store.getters.api.post(`company/${this.$route.params.slug}/contact/add`)
                .then(function (response) {
                    this.$emit("reload");
                }.bind(this));
        },
        deleteInContact() {
            this.$store.getters.api.put(`company/${this.$route.params.slug}/contact/delete`)
                .then(function (response) {
                    this.$emit("reload");
                }.bind(this));
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.info_box {
    height: 80px;
}

.btn_add_tag {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
        width: 30px;
        height: 30px;
        margin-left: 1px;
    }

    span {
        width: 30px;
        height: 30px;
    }
}

.btn_contact {
    padding: 0;

    svg {
        width: 45px;
        height: 45px;
        stroke: $green;
        background-color: transparent;
    }
}

.btn_contact_cancel {
    padding: 0;

    svg {
        width: 45px;
        height: 45px;
        stroke: $red;
        background-color: transparent;
    }
}

.tags_box,
.in_contact_with {
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;
}
</style>