<template>
    <div id="map" class="w-100">
        <button @click="sizeUp = !sizeUp" type="button"
            class="resize-map-btn btn btn_main d-flex align-items-center justify-content-center" data-bs-toggle="modal"
            data-bs-target="#enlargeMapModal">
            <icon icon="Resize" class="me-2 resize_icon"></icon>
            <span>{{ $t('company.resizeMap') }}</span>
        </button>

        <div class="modal fade" id="enlargeMapModal" tabindex="-1" aria-labelledby="enlargeMapModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered custom_modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div id="modal-map" style="width: 100%; height: 700px;"></div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn_secondary" data-bs-dismiss="modal">{{ $t('utils.close')
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        companyAddress: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            sizeUp: false,
            map: null,
            modalMap: null,
        }
    },
    mounted() {
        this.createData();
    },
    watch: {
        companyAddress: {
            handler: function () {
                this.createData();
            },
            deep: true
        },
        sizeUp(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.createModalMap();
                });
            }
        }
    },
    methods: {
        createData: function () {
            if (this.map) {
                this.map.remove();
            }

            this.map = L.map('map', {
                attributionControl: false,
                zoomControl: false
            }).setView([this.companyAddress.lat, this.companyAddress.lon], 13);

            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                maxZoom: 19
            }).addTo(this.map);

            L.marker([this.companyAddress.lat, this.companyAddress.lon]).addTo(this.map);
        },

        createModalMap: function () {
            if (this.modalMap) {
                this.modalMap.remove();
            }

            this.modalMap = L.map('modal-map', {
                attributionControl: false
            }).setView([this.companyAddress.lat, this.companyAddress.lon], 13);

            L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                maxZoom: 19
            }).addTo(this.modalMap);

            L.marker([this.companyAddress.lat, this.companyAddress.lon]).addTo(this.modalMap);

            // Adjust map size on modal openF
            const modalElement = document.getElementById('enlargeMapModal');
            modalElement.addEventListener('shown.bs.modal', () => {
                if (this.modalMap) {
                    this.modalMap.invalidateSize();
                }
            });
        },
    }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.resize-map-btn {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;

    span {
        font-size: 18px;
    }
}

.resize_icon {
    width: 31px;
    height: 31px;
}

.custom_modal {
    max-width: 70%;
    height: 100%;
}

@media screen and (max-width: 1400px) {
    .resize-map-btn {
        width: 100%;
        font-size: 16px;

        span {
            font-size: 16px;
        }
    }

    .resize_icon {
        width: 25px;
        height: 25px;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .resize-map-btn {
        width: 80%;
        font-size: 16px;

        span {
            font-size: 16px;
        }
    }

    .resize_icon {
        width: 25px;
        height: 25px;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
    .resize-map-btn {
        width: 70%;
        font-size: 17px;

        span {
            font-size: 16px;
        }
    }

    .resize_icon {
        width: 30px;
        height: 30px;
    }

}
</style>