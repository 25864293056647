<template>
    <div>
        <div class="row w-50 mx-auto">
            <div class="form-group">
                <label>{{ $t('company.name') }}</label>
                <input type="text" v-model="formData.name" class="form-control">
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.organizationId') }}</label>
                <input type="text" v-model="formData.ico" class="form-control">
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.taxId') }}</label>
                <input type="text" v-model="formData.taxId" class="form-control">
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.address') }}</label>
                <input type="text" v-model="formData.address" class="form-control">
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.employees') }}</label>
                <input type="number" v-model="formData.employees" class="form-control">
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.customer') }}</label>
                <select v-model="formData.customer" class="form-select">
                    <option v-for="contact in contacts" :key="contact.id">{{ contact.name }}</option>
                </select>
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.isConnected') }}</label>
                <select v-model="formData.isConnected" class="form-select" multiple>
                    <option v-for="institution in institutions" :key="institution.id" :value="institution.id">{{ institution.name }}</option>
                </select>
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.plan') }}</label>
                <select class="form-select" v-model="formData.plan">
                    <option v-for="plan in plans" :key="plan.id">{{ plan.plan }}</option>
                </select>
            </div>
            <div class="mt-3">
                <button class="btn btn_main w-25" @click="addInstitution()">{{ $t('utils.submit') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formData: {
                name: null,
                ico: null,
                taxId: null,
                address: null,
                employees: null,
                customer: null,
                isConnected: [],
                plan: null,
            },
            contacts: [],
            institutions: [],
            plans: [],
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/institutions").then(response => {
                this.institutions = response.data.items;
            })
            this.$store.getters.api.get("/plans").then(response => {
                this.plans = response.data.items;
            })
        },
        addInstitution() {
            this.$store.getters.api.post("/institution/add", this.formData).then(response => {
                this.$router.push({ name: 'Admin' })
            })
        }
    },
}
</script>

<style lang="">

</style>