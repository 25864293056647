<template>
    <div class="row justify-content-center align-items-center vh-100">
        <div class="col-12 col-md-10 col-lg-8 col-xxl-6">
            <div class="w-100 inner">
                <div>
                    <h1 class="text-center company_name">{{ $t("titles.companyName") }}</h1>
                </div>
                <div class="d-flex flex-wrap justify-content-between">
                    <h2 class="login_title">{{ $t("login.login") }}</h2>
                </div>
                <div class="d-flex input_group mt-2" :class="{ active: activeInput === 'username' }">
                    <label for="username" class="my-auto mx-4">
                        {{ $t("login.email") }}:
                    </label>
                    <input type="text" id="username" class="form-control" ref="username" v-model="username"
                        :placeholder="$t('login.yourUsername')" @keyup.enter="login()" required
                        @focus="activateInput('username')" @blur="deactivateInput()">
                </div>
                <div class="d-flex input_group mt-3" :class="{ active: activeInput === 'password' }">
                    <label for="password" class="my-auto mx-4">{{ $t("login.password") }}:</label>
                    <input type="password" id="password" class="" ref="password" v-model="password"
                        :placeholder="$t('login.yourPassword')" @keyup.enter="login()" required
                        @focus="activateInput('password')" @blur="deactivateInput()">
                </div>
                <div v-if="invalid" class="alert error mt-3 mb-1" role="alert">
                    {{ $t("login.invalid") }}
                </div>
            </div>
            <div class="text-center my-auto">
                <button type="submit" class="btn btn_submit" @click="login()">
                    <span>{{ $t("login.button") }}</span>
                </button>
            </div>
            <div class="text-center forgot_password pt-3">
                <router-link :to="{ name: 'PasswordReset' }">
                    {{ $t("login.PasswordReset") }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";

export default {
    data() {
        return {
            username: null,
            password: null,
            invalid: false,
            activeInput: false
        }
    },
    methods: {
        login: function () {
            this.$store.getters.api.post("/auth/login",
                { "username": this.username, "password": this.password }
            ).then(
                (response) => {
                    const data = response.data;
                    if (data.error_code == 100) {
                        this.invalid = true;
                        this.$refs.password.setCustomValidity(this.$t("login.invalid"));
                        this.$refs.username.setCustomValidity(this.$t("login.invalid"));
                        this.$refs.password.reportValidity();
                        this.$refs.username.reportValidity();
                    }
                    else {
                        store.commit("saveUserProfile", response.data.user);
                        const path = this.$route.query.path;
                        if (this.$store.state.user.institutions.length === 0) {
                            this.$router.push({ name: "NoInstitution" });
                        }
                        else if (path != null) {
                            this.$router.push(path);
                        } else {
                            this.$router.push({ name: "companies" });
                        }
                    }
                }
            )
        },
        activateInput(inputId) {
            this.activeInput = inputId;
        },
        deactivateInput() {
            // Clear the active input ID
            this.activeInput = null
        }
    },
    created: async function () {
        // try load data and possibly redirect
        this.$store.commit("getUserProfile");

        // wait for logging in 1 second
        let attempts = 0;
        const totalTime = 1000;
        const attemptTime = 100;
        while (attempts < totalTime / attemptTime) {
            if (this.$store.getters.isLoggedIn) {
                break;
            }
            await new Promise(r => setTimeout(r, attemptTime));
            attempts++;
        }

        if (this.$store.getters.isLoggedIn) {
            const path = this.$route.query.path;
            if (this.$store.state.user.institutions.length === 0) {
                this.$router.push({ name: "NoInstitution" });
            }
            else if (path != null) {
                this.$router.push(path);
            }
            else {
                this.$router.push({ name: "companies" });
            }

        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.inner {
    background: radial-gradient(circle, $light-purple 6%, $purple 100%);
    border-radius: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 45px 50px 90px 50px;
    margin: 0 0 20px 0;
}

.company_name {
    font-family: "Outfit";
    font-weight: 800;
    font-size: 66px;
    text-shadow: 5px 5px 10px $black;
    color: $white;
}

.login_title {
    color: $white;
    font-size: 30px;
    text-shadow: 2px 2px 5px $black;
}

.input_group {
    background-color: $white;
    border-radius: 50px;

    label {
        color: $black;
        width: 35%;
        font-size: 20px;
    }

    input {
        background-color: $white;
        border: none;
        width: 90%;
        outline: none;
        border-radius: 0 50px 50px 0;
        padding: 20px;
        font-size: 20px;
    }
}

.input_group.active label {
    transition: all 0.2s ease-in-out;
    display: none
}

.input_group.active {
    background-color: $white;
    padding: 0
}

.input_group.active input {
    width: 100%;
    opacity: 1;
    padding: 20px 20px;
    border-radius: 50px;
    outline: none;
    border: none;
}

.forgot_password {
    a {
        color: $white;
        font-size: 20px;
        text-decoration: none;
        border: none;
    }

    a:hover {
        color: $primary;
    }
}

.error {
    color: $light-red;
    font-family: 'Rubik One Regular', sans-serif;
    font-size: 20px;
    background-color: $beige;
    border-radius: 35px;
    text-align: center;
}
</style>