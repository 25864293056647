// common configuration
const config = {
    api: {
        baseUrl: "https://salesman.digitalwish.cz/api"
    },
    afterLoginRoute: "companies",
    vueAppUrl: "https://salesman.digitalwish.cz",
    MAX_FILE_SIZE_MB: 10485760, // Maximum file size in MB
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config["vueAppUrl"] = "http://65.108.224.246:10201";
    config["api"]["baseUrl"] = "http://65.108.224.246:10200";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_tomas") {
    config["vueAppUrl"] = "http://localhost:36001";
    config["api"]["baseUrl"] = "http://localhost:36000";
}

export const CONFIG = config;