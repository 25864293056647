<template>
    <div v-if="institution" class="content">
        <div>
            <h2>{{ institution.name }}</h2>
        </div>
        <div class="mt-4">
            <div class="mt-2 block">
                <span v-if="employees">Počet zaměstnanců: {{ employees.length }}</span>
                <span v-else>Počet zaměstnanců: -</span>
            </div>
            <div class="mt-2 block">
                <InstitutionUsers :institutionId="institutionId" :fromAdmin="fromAdmin" />
            </div>
            <div class="mt-3 block">
                <span>Počet zaplacených Uživatelů: {{ institution.paid_users }}</span>
                <input v-if="editingPaidUsers" type="number" class="form-control" v-model="newPaidUsers">
                <button v-if="!editingPaidUsers" @click="editingPaidUsers = !editingPaidUsers"
                    class="btn btn_main ms-3">
                    {{ $t("utils.edit") }}
                </button>
                <button @click="sumbitEditedPaidUsers()" class="btn btn_main ms-3" v-if="editingPaidUsers">
                    {{ $t("utils.save") }}
                </button>
                <button v-if="editingPaidUsers" @click="editingPaidUsers = !editingPaidUsers"
                    class="btn btn_secondary ms-3">
                    {{ $t("utils.cancel") }}
                </button>
            </div>
            <div class="mt-2 block">
                <span>Zaplaceno do:
                    <i18n-d v-if="institution.paid_to" tag="span" :value="new Date(institution.paid_to)"></i18n-d>
                </span>

                <input v-if="editingPaidTo" type="date" class="form-control" v-model="newPaidTo">
                <button v-if="!editingPaidTo" @click="editPaidTo()" class="btn btn_main ms-3">
                    {{ $t("utils.edit") }}
                </button>
                <button @click="sumbitEditedPaidTo()" class="btn btn_main ms-3" v-if="editingPaidTo">
                    {{ $t("utils.save") }}
                </button>
                <button v-if="editingPaidTo" @click="editingPaidTo = !editingPaidTo" class="btn btn_secondary ms-3">
                    {{ $t("utils.cancel") }}
                </button>
            </div>
            <div class="mt-2 tags block">
                <InstitutionTags :institutionId="institutionId" />
            </div>
            <div class="mt-2 row block">
                <div>
                    <span class="pe-2">Plán:</span>
                    <span v-if="institution.plan">{{ institution.plan.plan }}</span>
                </div>
                <div v-if="institution.plan">
                    <div>
                        <span class="pe-2">Cena base:</span>
                        <span v-if="institution.plan.base_price">{{ institution.plan.base_price }}</span>
                        <span v-else>-</span>
                    </div>
                    <div>
                        <span class="pe-2">Cena per user:</span>
                        <span v-if="institution.plan.user_price">{{ institution.plan.user_price }}</span>
                        <span v-else>-</span>
                    </div>
                    <div>
                        <span class="pe-2">Doba:</span>
                        <span v-if="institution.plan.duration">{{ institution.plan.duration }}</span>
                        <span v-else>-</span>
                    </div>
                    <div>
                        <span class="pe-2">Max. velikost souborů:</span>
                        <span v-if="institution.plan.max_file_size">{{ institution.plan.max_file_size }}</span>
                        <span v-else>-</span>
                    </div>
                    <div>
                        <span class="pe-2">default:</span>
                        <span v-if="institution.plan.default">{{ institution.plan.default }}</span>
                        <span v-else>-</span>
                    </div>
                    <div>
                        <span class="pe-2">E-maily nových firem:</span>
                        <span v-if="institution.plan.new_companies_email">{{ institution.plan.new_companies_email
                            }}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <div class="mt-3">
                    <button @click="editingPlan = !editingPlan" class="btn btn_main">
                        <span class="pt-1">Změnit</span>
                    </button>
                    <button data-bs-toggle="modal" data-bs-target="#createPlanModal" class="btn btn_main ms-3">
                        <span class="pt-1">Vytvořit nový</span>
                    </button>
                </div>
            </div>
            <div v-if="editingPlan" class="mt-3 w-50 block">
                <div class="mt-3">
                    <select class="form-select" v-model="selectedPlan">
                        <option v-for="plan in plans" :key="plan.id" :value="plan.id">{{ plan.plan }}</option>
                    </select>
                </div>
                <div class="mt-3">
                    <button class="btn btn_main w-100" @click="editPlan()">Uložit</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="createPlanModal" tabindex="-1" aria-labelledby="createPlanModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createPlanModalLabel">Vytvořit nový</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mt-3">
                        <label>Název:</label>
                        <input type="text" class="form-control" v-model="newPlan.name">
                    </div>
                    <div class="mt-3">
                        <label>Cena base:</label>
                        <input type="number" class="form-control" v-model="newPlan.base_price">
                    </div>
                    <div class="mt-3">
                        <label>Cena per user:</label>
                        <input type="number" class="form-control" v-model="newPlan.user_price">
                    </div>
                    <div class="mt-3">
                        <label>Trvání:</label>
                        <select v-model="newPlan.duration" id="plan-duration" class="form-select">
                            <option v-for="(label, value) in durationChoices" :key="value" :value="value">
                                {{ label }}
                            </option>
                        </select>
                    </div>
                    <div class="mt-3">
                        <label>Maximální velikost souborů:</label>
                        <input type="number" class="form-control" v-model="newPlan.max_file_size">
                    </div>
                    <div class="mt-3">
                        <label>Odesílat emaily o nových firmách:</label>
                        <input type="checkblock" v-model="newPlan.new_companies_email">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn_secondary" data-bs-dismiss="modal">Zrušit</button>
                    <button @click="addPlan()" type="button" class="btn btn_main">Uložit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InstitutionUsers from '@/components/Institution/InstitutionUsers.vue';
import InstitutionTags from '@/components/Institution/InstitutionTags.vue';


export default {
    components: {
        InstitutionUsers,
        InstitutionTags
    },
    data() {
        return {
            institution: {},
            employees: [],
            tags: [],
            durationChoices: {},
            plans: [],
            institutionId: null,
            editingPlan: false,
            selectedPlan: null,
            newPlan: {
                name: null,
                base_price: null,
                user_price: null,
                duration: null,
                max_file_size: null,
                default: false,
                new_companies_email: false
            },
            editingPaidTo: false,
            newPaidTo: null,
            editingPaidUsers: false,
            newPaidUsers: null,
            fromAdmin: null,
        }
    },
    created() {
        this.institutionId = this.$route.params.institution_id;
        this.fromAdmin = true
        this.loadData();
    },
    methods: {
        loadData() {
            this.$store.getters.api.get(`/institution/${this.institutionId}/admin`).then(response => {
                this.institution = response.data.institution;
                this.employees = response.data.employees;
                this.tags = response.data.tags;
            });
            this.$store.getters.api.get("/plans").then(response => {
                this.plans = response.data.items;
            });
            this.$store.getters.api.get('/plan-choices').then(response => {
                this.durationChoices = response.data.choices;
            });
        },
        editPlan() {
            const selectedPlan = { plan_id: this.selectedPlan };
            this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, selectedPlan).then(response => {
                this.loadData();
                this.editingPlan = false;
            })
        },
        addPlan() {
            this.$store.getters.api.post(`/plan/institution/${this.institutionId}/add`, this.newPlan).then(response => {
                window.location.reload()
            })
        },

        editPaidTo() {
            this.editingPaidTo = !this.editingPaidTo
        },
        sumbitEditedPaidTo() {
            this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, { paid_to: this.newPaidTo }).then(response => {
                this.loadData();
                this.editingPaidTo = false;
            })
        },

        editPaidUsers() {
            this.editingPaidUsers = !this.editingPaidUsers
        },
        sumbitEditedPaidUsers() {
            this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, { paid_users: this.newPaidUsers }).then(response => {
                this.loadData();
                this.editingPaidUsers = false;
            })
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.box {
    background-color: $light-grey;
    color: $black;
}

h2 {
    font-size: 40px;
}

.content {
    font-size: 20px;
}

.color-circle {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #000;
}

svg {
    width: 35px;
    height: 35px;
}

.icon_bin {
    color: $purple;
    background-color: transparent;
}

.icon_edit {
    stroke: $purple;
    color: $purple;
    background-color: transparent;
}
</style>
