<template>
    <div class="error_container row p-3">
        <div class="row inputs">
            <div class="col-4 name_input">
                <input class="w-100 form-control" disabled placeholder="Vyhledat podle názvu nebo IČA">
            </div>
            <div class="col-4">
                <input class="w-100 form-control" disabled placeholder="Počet zaměstnanců">
            </div>
            <div class="col-4">
                <input class="w-100 form-control" disabled placeholder="Živnosti">
            </div>
            <div class="col-4 mt-2">
                <input class="w-100 form-control" disabled placeholder="Typ společnosti">
            </div>
            <div class="col-4 mt-2">
                <input class="w-100 form-control" disabled placeholder="Štítky">
            </div>
            <div class="col-4 mt-2">
                <input class="w-100 form-control" disabled placeholder="Datum založení">
            </div>
            <div class="col-4 mt-2">
                <input class="w-100 form-control" disabled placeholder="Aktivní i zaniklé">
            </div>
            <div class="col-4 mt-2">
                <input class="w-100 form-control" disabled placeholder="V kontaktu s">
            </div>
            <div class="col-4 mt-2">
                <button type="button" disabled class="btn btn_main">
                    {{ $t('company.searchByAddress') }}
                </button>
            </div>
        </div>
        <div class="row table_header mt-4">
            <span class="col-2">{{ $t("company.name") }}</span>
            <span class="col-1">{{ $t("company.organizationId") }}</span>
            <span class="col-3">{{ $t("company.address") }}</span>
            <span class="col-2">{{ $t("company.inContactWith") }}</span>
            <span class="col-2">{{ $t("company.tags") }}</span>
        </div>
        <div class="fake_table mt-2">
        </div>
    </div>
    <div class="error_text text-center mx-auto">
        <h3 class="my-auto">{{ error }}</h3>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.error_container {
    padding: 20px;
    border-radius: 8px;
    opacity: 0.4;
}

.form-control {
    border-radius: 50px;
    padding: 10px 20px;
    border: none;
    font-weight: 400;
    font-size: 1rem;
    color: $black;
    font-family: 'Inter';
}

.table_header {
    color: $white;
    font-weight: 700;
    font-size: 18px;
    background-color: transparent;
}

.fake_table {
    min-height: 500px;
    background-color: $white;
    border-radius: 35px;
    position: relative;
}

.error_text {
    color: $light-red;
    font-weight: 700;
    width: 60%;
    border-radius: 35px;
    padding: 30px 40px;
    font-size: 35px;
    background-color: $beige;
    font-family: 'Rubik One Regular', sans-serif;
    z-index: 100;
    margin-top: 30px;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}
</style>