<template>
  <div class="container-fluid">
    <div class="row">
      <BaseTemplate v-if="showNavbar" :show-navbar="showNavbar">
        <router-view />
      </BaseTemplate>
      <router-view v-else />
    </div>
  </div>
</template>

<script>
import BaseTemplate from "@/components/templates/BaseTemplate.vue"

export default {
  components: {
    BaseTemplate
  },
  data() {
    return {
    }
  },
  computed: {
    pageTitle: function () {
      const title = this.$route.meta.title;
      let title_tranlation = null;

      if (title != null) {
        title_tranlation = this.$t(title);
        document.title = "Salesman - " + this.$t(title);
      }

      return title_tranlation;
    },
    showNavbar: function () {
      if (this.$route.meta.navbar != null) {
        return this.$route.meta.navbar !== false;;
      }
      return true;
    },
  },
  watch: {
    // lookout for changes
    pageTitle: function () { }
  }
}
</script>

<style lang="scss">
@import "@/scss/main.scss";

footer {
  height: 2.5rem;
  /* Footer height */
}
</style>