<template>
    <div class="add_institution">
        <div class="row justify-content-center align-items-center vh-100">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6">
                <div class="w-100 inner">
                    <div>
                        <h1 class="text-center company_name">{{ $t("titles.companyName") }}</h1>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between mt-4">
                        <h2 class="login_title">{{ $t("institution.register.title") }}</h2>
                    </div>
                    <div v-if="invalid" class="alert alert-danger mt-3 mb-1" role="alert">
                        {{ invalid }}
                    </div>
                    <!-- Loading after clicking register button -->
                    <div v-if="loading" class="text-center mt-2">
                        <p>{{ $t("general.loading") }}</p>
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                    <div class="d-flex input_group mt-4">
                        <label for="company_name" class="py-3 mx-4">
                            {{ $t("institution.register.name") }}:
                        </label>
                        <input type="text" ref="company_name" v-model="formData.company_name">
                    </div>
                    <div class="d-flex input_group mt-4">
                        <label for="ico" class="py-3 mx-4">
                            {{ $t("institution.register.organization_id") }}:
                        </label>
                        <input type="number" ref="ico" v-model="formData.ico">
                    </div>
                    <div class="d-flex input_group mt-4">
                        <label for="dic" class="py-3 mx-4">
                            {{ $t("institution.register.dic") }} ({{ $t("general.optional") }}):
                        </label>
                        <input type="number" ref="dic" v-model="formData.dic">
                    </div>
                    <div class="d-flex input_group mt-4">
                        <label for="residence" class="py-3 mx-4">
                            {{ $t("institution.register.residence") }}:
                        </label>
                        <input type="text" ref="residence" v-model="formData.residence">
                    </div>
                    <div class="d-flex input_group mt-3">
                        <label for="employees" class="py-3 mx-4">{{ $t("institution.register.employesCount") }}:</label>
                        <input type="number" ref="employees" v-model="formData.employees">
                    </div>
                    <div class="checkbox_group mt-3">
                        <div class="form-check">
                            <input type="checkbox" class="me-2 form-check-input checkbox" ref="checkbox"
                                v-model="formData.PersonalDataCheck">
                            <label for="personalDataCheck" class="form-check-label pt-1">
                                {{ $t("register.protectionOfPersonalData") }}
                            </label>
                        </div>
                        <div class="form-check mt-1">
                            <input type="checkbox" class="me-2 form-check-input checkbox" ref="checkbox"
                                v-model="formData.termsAndConditions">
                            <label class="form-check-label pt-1">
                                {{ $t("register.termsAndConditions") }}
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="!loading" class="text-center">
                    <button type="submit" class="btn btn_submit" @click="register()">
                        <span>{{ $t("institution.register.button") }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            formData: {
                company_name: "",
                ico: "",
                dic: "",
                residence: "",
                employees: null,
                PersonalDataCheck: false,
                termsAndConditions: false,
            },
            invalid: false,
            loading: false
        }
    },
    methods: {
        register: function () {
            this.invalid = false
            this.loading = true;
            this.$store.getters.api.post("/institution/register", this.formData)
                .then(
                    (response) => {
                        this.loading = false;
                        const data = response.data;
                        if (data.error_code == 108) {
                            this.invalid = (this.$t("error.108"));
                            this.$refs.company_name.setCustomValidity(this.$t("error.108"));
                            this.$refs.company_name.reportValidity();
                        }
                        else if (data.error_code == 109) {
                            this.invalid = (this.$t("error.109"));
                            this.$refs.ico.setCustomValidity(this.$t("error.108"));
                            this.$refs.ico.reportValidity();
                        }
                        else if (data.error_code == 111) {
                            this.invalid = (this.$t("error.111"));
                            this.$refs.residence.setCustomValidity(this.$t("error.111"));
                            this.$refs.residence.reportValidity();
                        }
                        else if (data.error_code == 112) {
                            this.invalid = (this.$t("error.112"));
                            this.$refs.employees.setCustomValidity(this.$t("error.112"));
                            this.$refs.employees.reportValidity();
                        }
                        else if (data.error_code == 113) {
                            this.invalid = (this.$t("error.113"));
                        }
                        else if (data.error_code == 114) {
                            this.invalid = (this.$t("error.114"));
                        }
                        else {
                            window.location.reload()
                        }
                    }
                )
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.inner {
    background: radial-gradient(circle, $light-purple 6%, $purple 100%);
    border-radius: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 45px 50px 50px 50px;
    margin: 0 0 20px 0;
    overflow-y: hidden;
}

.company_name {
    font-family: "Outfit";
    font-weight: 800;
    font-size: 66px;
    text-shadow: 5px 5px 10px $black;
    color: $white;
}

.login_title {
    color: $white;
    font-size: 30px;
}

.input_group {
    background-color: $white;
    border-radius: 50px;

    label {
        color: $black;
        width: 45%;
        font-size: 20px;
    }

    input {
        background-color: $white;
        border: none;
        width: 65%;
        outline: none;
        border-radius: 0 50px 50px 0;
        padding: 0 20px;
        font-size: 20px;
    }
}
.form-check-input[type=checkbox] {
    border-radius: 50%;
    border: none;
    width: 1.2em;
    height: 1.2em;
}
</style>