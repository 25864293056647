<template>
    <div class="text-center mt-2">
        <h3 class="title">{{ $t("institution.invoicing.title") }}</h3>
    </div>
    <div class="d-flex mt-3">
        <div class="d-flex col-6">
            <p class="me-2">{{ $t("institution.invoicing.paidFor") }}:</p>
            <span> {{ institution.paid_users }}</span>
        </div>
        <div class="d-flex col-6">
            <p class="me-2">{{ $t("institution.invoicing.paidTo") }}:</p>
            <i18n-d v-if="institution.paid_to" tag="span" :value="new Date(institution.paid_to)"></i18n-d>
        </div>
    </div>
    <div class="invoices mt-3" style="max-height: 550px; overflow-y: auto;">
        <table class="table table-responsive  table-hover w-100 mt-3">
            <thead class="table_head">
                <tr>
                    <th>{{ $t("institution.invoicing.invoiceNumber") }}</th>
                    <th>{{ $t("institution.invoicing.invoiceDueDate") }}</th>
                    <th>{{ $t("institution.invoicing.invoiceAmount") }}</th>
                    <th>{{ $t("institution.invoicing.invoicePaid") }}</th>
                </tr>
            </thead>
            <tbody class="align-middle">
                <tr v-if="invoices" v-for="invoice in invoices" :key="invoice.id" class="align-middle">
                    <td>
                        <span>{{ invoice.trivi_id }}</span>
                    </td>
                    <td>
                        <i18n-d v-if="invoice.due_date" tag="span" :value="new Date(invoice.due_date)"></i18n-d>
                        <span v-else></span>
                    </td>
                    <td>
                        <span>{{ invoice.total }} Kč</span>
                    </td>
                    <td>
                        <icon v-if="invoice.invoice_paid" class="icon_true" icon="True" />
                        <icon v-if="!invoice.invoice_paid" class="icon_false" icon="False" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        institutionId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            institution: {},
            invoices: [],
        }
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get(`/institution/${this.institutionId}/settings`).then(function (response) {
                this.institution = response.data.institution;
                this.invoices = response.data.invoices;
            }.bind(this));
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    font-size: 40px;
    color: $white;
    font-weight: 500;
    font-family: 'Rubik One Regular', sans-serif;
}

.table-invoices {
    --bs-table-bg: transparent;
    --bs-table-color: $white;
}

.icon_true {
    color: green;
    stroke: green;
    width: 35px;
    height: 35px;
}

.icon_false {
    color: red;
    stroke: red;
    width: 35px;
    height: 35px;
}
</style>