<template>
    <div class="col-6 col-md-5 col-xxl-3">
        <!-- Show loading indicator while waiting for avatar -->
        <div v-if="isLoading" class="loading">Loading...</div>

        <!-- Display avatar once it is available -->
        <div v-else-if="userAvatarUrl">
            <img :src="userAvatarUrl" alt="Avatar" class="avatar-img" />
            <span v-if="user.name && user.name.length > 1" class="hover-text">{{ user.name }} {{ user_role }}</span>
            <span v-else-if="user.username" class="hover-text">{{ user.username }} {{ user_role }}</span>
            <span class="hover-text">{{ user_role }}</span>
        </div>

        <!-- Fallback when no avatar is available -->
        <div v-else>
            <icon icon="UserPicture" class="avatar-icon" />
            <span v-if="user.name && user.name.length > 1" class="hover-text">{{ user.name }} {{ user_role }}</span>
            <span v-else-if="user.username" class="hover-text">{{ user.username }} {{ user_role }}</span>
            <span class="hover-text">{{ user_role }}</span>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        user_role: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            hrefPhoto: null,
            isLoading: true,
        };
    },
    mounted() {
        if (this.user && this.user.avatar) {
            this.downloadPhoto();
        } else {
            this.isLoading = false;
        }
    },
    computed: {
        userAvatarUrl() {
            return this.hrefPhoto ? this.hrefPhoto : "https://via.placeholder.com/1000";
        },
    },
    methods: {
        async downloadPhoto() {
            try {
                const response = await this.$store.getters.api.get('/auth/avatar', { responseType: 'blob' });
                this.hrefPhoto = URL.createObjectURL(response.data);
            } catch (error) {
                console.error("Failed to download photo", error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.avatar-img {
    width: 45px;
    height: 45px;
    padding: 0;
}

.avatar-icon {
    font-size: 75px;
    border-radius: 50%;
}

.hover-text {
    display: none;
    position: absolute;
    background-color: $white;
    padding: 5px;
    border: 1px solid $primary;
    z-index: 1;
    font-size: 16px;
}

.avatar-icon:hover+.hover-text {
    display: block;
}
.avatar-img:hover+.hover-text {
    display: block;
}

.loading {
    font-size: 16px;
    color: $primary;
}
</style>
